import { CommonActions } from '@bugbug/core/actions/actions';
import { RUN_STATUS } from '@bugbug/core/constants/status';
import { STEP_TYPE } from '@bugbug/core/constants/steps';
import { push, replace } from 'connected-react-router';
import { NOT_FOUND } from 'http-status';
import {
  complement,
  difference,
  equals,
  filter,
  findIndex,
  insert,
  is,
  isEmpty,
  pipe,
  prop,
  propEq,
  path,
} from 'ramda';
import { all, call, put, select, takeLatest, take } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { RUN_ENV } from '~/modules/constans';
import { selectTestRunIdFromUrl } from '~/modules/misc.selectors';
import { selectCurrentOrganizationId } from '~/modules/organization/organization.selectors';
import {
  selectProjectHomepageUrl,
  selectProjectSlug,
  selectSingleProject,
  selectSingleProjectId,
} from '~/modules/project/project.selectors';
import { selectLocation } from '~/modules/router.selectors';
import { TestActions, TestTypes } from '~/modules/test/test.redux';
import {
  deleteGroupsStepsListCache,
  selectDefaultStepsParams,
  selectGroup,
  selectLastRunsByTestsIds,
  selectOrderedGroupPartials,
  selectOrderedGroupPartialsIndices,
  selectSingleTest,
  selectTestGroup,
  selectTestGroupsList,
  selectGroupStepsList,
  selectTestsList,
  selectTestStep,
  selectTestStepsTemporaryIds,
  selectSortedSteps,
  selectTestGroups,
  selectTestIsRecording,
} from '~/modules/test/test.selectors';
import { pickDefaultStepParams } from '~/modules/test/test.utils';
import { TestRunActions, TestRunTypes } from '~/modules/testRun/testRun.redux';
import { selectPlaybackCursorPosition } from '~/modules/uiState/uiState.selectors';
import {
  selectHasNotCreatedTest,
  selectHasNotStartedRecording,
  selectUserFlags,
  selectUserId,
  selectUserSettings,
} from '~/modules/user/user.selectors';
import selectWebsocketChannelName from '~/modules/websocket/websocket.selectors';
import analytics, { TRACK_EVENT_ARG_TYPE, TRACK_EVENT_TYPE } from '~/services/analytics';
import api from '~/services/api/index';
import toasts from '~/services/toasts';
import {
  ACTIONS,
  ENTITIES,
  showInternalServerError,
  showPendingRequestToast,
} from '~/services/toasts/internalServerError';
import { showGetDefaultStepsParamsError } from '~/services/toasts/tests';
import i18n from '~/translations';
import { isNewPlaybackSupported } from '~/utils/misc';
import { getRelatedTest, isTriggeredByCurrentUser } from '~/utils/runs';
import { getInitialValues } from '~/views/TestDetails/components/StepDetails/StepDetails.helpers';
import urls, { reverse } from '~/views/urls';

import { dispatchInExtension } from '../extension/extension.dispatch';
import { selectExtensionSettings } from '../extension/extension.selectors';
import { UIStateActions } from '../uiState/uiState.redux';

export const showTestError = showInternalServerError(ENTITIES.TEST);
export const showTestsError = showInternalServerError(ENTITIES.TESTS);
export const showStepError = showInternalServerError(ENTITIES.STEP);
export const showStepsError = showInternalServerError(ENTITIES.STEPS);
export const showGroupError = showInternalServerError(ENTITIES.GROUP);
export const showPendingTest = showPendingRequestToast(ENTITIES.TEST);
export const showPendingGroup = showPendingRequestToast(ENTITIES.GROUP);

function* createRequest({ payload = {} }) {
  try {
    const testsList = yield select(selectTestsList);
    const hasNotCreatedTest = yield select(selectHasNotCreatedTest);
    if (hasNotCreatedTest) {
      yield call(analytics.trackEvent, TRACK_EVENT_TYPE.FIRST_NEW_TEST);
    }
    yield call(analytics.trackEvent, TRACK_EVENT_TYPE.CREATE_TEST, {
      [TRACK_EVENT_ARG_TYPE.TEST_NAME]: payload.name,
      [TRACK_EVENT_ARG_TYPE.TESTS_COUNT]: (testsList?.length ?? 0) + 1,
    });
    const project = yield select(selectSingleProject);
    const organizationId = yield select(selectCurrentOrganizationId);

    const { data: responseData } = yield call(api.tests.create, {
      ...payload,
      projectId: project.id,
    });

    yield put(TestActions.createSuccess(responseData));
    yield call(analytics.trackEvent, TRACK_EVENT_TYPE.OPEN_TEST);
    yield put(
      replace(
        reverse(urls.test, {
          projectId: project.id,
          projectSlug: project.slug,
          organizationId,
          testId: responseData.id,
        }),
      ),
    );
  } catch (error) {
    yield call(showTestError, ACTIONS.CREATE, error, true);
    yield put(TestActions.createFailure(error));
  }
}

export function* getListRequest({ query, sortBy, descOrder = false }) {
  try {
    const projectId = yield select(selectSingleProjectId);
    let ordering = sortBy;

    if (descOrder) {
      ordering = `-${ordering}`;
    }
    const params = {
      project_id: projectId,
      query,
      ordering,
      pagination: 'off',
    };
    const { data: responseData } = yield call(api.tests.getList, params);

    yield put(TestActions.getListSuccess(responseData));
  } catch (error) {
    yield put(TestActions.getListFailure(error));
  }
}

export function* removeRequest({ ids }) {
  try {
    if (ids) {
      yield call(api.tests.removeSelected, { testsIds: ids });

      if (ids.length === 1) {
        yield call(toasts.showSuccess, {
          content: i18n.t('test.deleteSuccessInfo', 'Test has been deleted successfully.'),
        });
      } else {
        yield call(toasts.showSuccess, {
          content: i18n.t(
            'test.selectedDeleteSuccessInfo',
            'Tests have been deleted successfully.',
          ),
        });
      }
    } else {
      const project = yield select(selectSingleProject);
      yield call(api.tests.removeSelected, { projectId: project.id });
      yield call(toasts.showSuccess, {
        content: i18n.t('test.allDeleteSuccessInfo', 'All tests have been deleted successfully.'),
      });
    }
    yield call(
      analytics.trackEvent,
      ids?.length === 1 ? TRACK_EVENT_TYPE.DELETE_TEST : TRACK_EVENT_TYPE.BULK_DELETE_TESTS,
    );
    yield put(TestActions.removeSuccess(ids));
  } catch (error) {
    yield put(TestActions.removeFailure(error));
  }
}

export function* removePartialsRequest({ id, steps, groups }) {
  try {
    const { data: removed } = yield call(api.tests.removePartials, id, {
      steps,
      groups,
    });

    const stepPositionBeforeRemovedSteps = yield call(findStepPositionBeforeRemovedStepsAndGroups, {
      removedStepsIds: removed.steps,
      removedGroupsIds: removed.groups,
    });

    yield put(TestActions.removePartialsSuccess(id, removed.steps, removed.groups));

    yield call(setPlaybackCursorPositionAfterStep, stepPositionBeforeRemovedSteps);
    yield call(stopRunnerIfRemovedAllSteps);
  } catch (error) {
    yield put(TestActions.removePartialsFailure(error));
  }
}

export function* clone({ id, shouldNavigate }) {
  try {
    yield call(analytics.trackEvent, TRACK_EVENT_TYPE.DUPLICATE_TEST);
    const { data } = yield call(api.tests.clone, id);
    yield put(TestActions.cloneSuccess(data));

    if (shouldNavigate) {
      const organizationId = yield select(selectCurrentOrganizationId);
      const projectSlug = yield select(selectProjectSlug);
      const projectId = yield select(selectSingleProjectId);

      yield call(analytics.trackEvent, TRACK_EVENT_TYPE.OPEN_TEST);
      yield put(
        push(
          reverse(urls.test, {
            projectId,
            projectSlug,
            testId: data.id,
            organizationId,
          }),
        ),
      );
    }
  } catch (error) {
    yield put(TestActions.cloneFailure(error));
  }
}

export function* cleanSteps({ payload: { testId } }) {
  try {
    const location = yield select(selectLocation);
    const testRunId = yield select(selectTestRunIdFromUrl);
    const { data: responseData } = yield call(api.tests.cleanSteps, testId);
    yield put(TestActions.cleanStepsSuccess(responseData));

    if (testRunId) {
      yield put(push(location.path));
    }
  } catch (error) {
    yield call(showTestError, ACTIONS.UPDATE, error);
    yield put(TestActions.cleanStepsFailure(error));
  }
}

export function* removeStepsRequest({ testId, stepsIds }) {
  try {
    const temporaryStepsIds = yield select(selectTestStepsTemporaryIds);

    if (!stepsIds) {
      yield call(cleanSteps, { payload: { testId } });
      yield put(TestActions.removeStepsSuccess(testId, stepsIds));
      return;
    }

    const stepsIdsToRemove = difference(stepsIds, temporaryStepsIds);
    if (stepsIdsToRemove.length) {
      const params = { testId, stepsIds: stepsIdsToRemove };
      yield call(api.tests.removeSteps, params);
    }

    const stepPositionBeforeRemovedSteps = yield call(findStepPositionBeforeRemovedStepsAndGroups, {
      removedStepsIds: stepsIdsToRemove,
      removedGroupsIds: [],
    });

    yield put(TestActions.removeStepsSuccess(testId, stepsIds));

    yield call(setPlaybackCursorPositionAfterStep, stepPositionBeforeRemovedSteps);
    yield call(stopRunnerIfRemovedAllSteps);
  } catch (error) {
    yield call(showStepsError, ACTIONS.DELETE, error);
    yield put(TestActions.removeStepsFailure(error));
  }
}

export function* updateGroupPosition({ testId, groupId, index }) {
  try {
    yield put(TestActions.updateGroupPositionSuccess(testId, groupId, index));
    const groupsList = yield select(selectTestGroupsList);
    const groupsOrder = groupsList.map((group, itemIndex) => ({
      groupId: group.id,
      index: itemIndex,
    }));
    yield call(api.tests.updateGroupsOrder, testId, groupsOrder);
    yield call(refreshPlaybackCursorPosition);
  } catch (error) {
    showInternalServerError(ENTITIES.GROUP, ACTIONS.UPDATE, error);
    yield put(TestActions.updateGroupPositionFailure(error));
  }
}

export function* updateStepsPosition({
  testId,
  stepId,
  sourceGroupId,
  sourceIndex,
  destGroupId,
  destIndex,
}) {
  const sourcePartials = yield select(selectOrderedGroupPartials(sourceGroupId));
  const sourcePartialsIndices = yield select(selectOrderedGroupPartialsIndices(sourceGroupId));
  const getIndex = pipe(filter(complement(prop('steps'))), findIndex(propEq('id', stepId)));
  const sourceStepListIndex = getIndex(sourcePartials);
  const [removedStep] = sourcePartials.splice(sourceIndex, 1);
  const cursorPosition = yield select(selectPlaybackCursorPosition);
  const sortedSteps = yield select(selectSortedSteps);
  const cursorStepIndex = sortedSteps.findIndex(
    (step) => step.id === cursorPosition?.afterStep?.id,
  );
  const hasGroupChanged = sourceGroupId !== destGroupId;

  const destPartialsIndices = yield select(selectOrderedGroupPartialsIndices(destGroupId));
  let destPartials = hasGroupChanged
    ? yield select(selectOrderedGroupPartials(destGroupId))
    : sourcePartials;

  destPartials = insert(destIndex, removedStep, destPartials);
  const destStepListIndex = getIndex(destPartials);

  try {
    yield put(
      TestActions.updateStepsPositionSuccess(
        stepId,
        destGroupId,
        sourcePartials,
        destPartials,
        sourcePartialsIndices,
        destPartialsIndices,
      ),
    );

    if (cursorPosition?.afterStep?.id) {
      const updatedSortedSteps = yield select(selectSortedSteps);

      const reorderedAfterStep = updatedSortedSteps[cursorStepIndex];
      const reorderedNextStep = updatedSortedSteps[cursorStepIndex + 1];

      const position = {
        afterStep: reorderedAfterStep
          ? {
              id: reorderedAfterStep.id,
              groupId: reorderedAfterStep.groupId,
            }
          : null,
        runningStep: null,
        nextStep: reorderedNextStep
          ? {
              id: reorderedNextStep.id,
              groupId: reorderedNextStep.groupId,
            }
          : null,
      };

      yield put(CommonActions.setPlaybackCursorPosition(position));
      yield call(
        dispatchInExtension,
        CommonActions.setPlaybackCursorPositionIfPausedOrIdle(position),
      );
    } else {
      yield call(refreshPlaybackCursorPosition);
    }

    yield call(api.tests.updateStepsPosition, testId, {
      destinationGroupId: destGroupId,
      index: destStepListIndex,
      stepsIds: [stepId],
    });

    // From model perspective unconfirmed group is not present in the steps list.
    // So we need to detect the case if step position change was occured only with related unconfirmed group
    if (
      !hasGroupChanged &&
      sourceStepListIndex === destStepListIndex &&
      sourceIndex !== destIndex
    ) {
      const isUpMove = sourceIndex > destIndex;
      const unconfirmedGroup = sourcePartials[isUpMove ? sourceIndex - 1 : sourceIndex];

      yield call(api.groups.update, unconfirmedGroup.id, {
        unconfirmedIndex: isUpMove
          ? unconfirmedGroup.unconfirmedIndex + 1
          : unconfirmedGroup.unconfirmedIndex - 1,
      });
    }
  } catch (error) {
    yield call(showStepsError, ACTIONS.UPDATE, error);
    yield put(TestActions.updateStepsPositionFailure(error));
  }
}

export function* createStepArtifact({ file }) {
  const projectId = yield select(selectSingleProjectId);
  const { data: savedArtifact } = yield call(api.artifacts.create, projectId, file);
  return savedArtifact;
}

export function* saveStepSettings({ id, groupId, settings, meta }) {
  try {
    const data = {
      ...settings,
      runTimeout: isEmpty(settings.runTimeout) ? null : settings.runTimeout,
      sleep: isEmpty(settings.sleep) ? null : settings.sleep,
    };

    const stepsList = yield select(selectGroupStepsList(id));

    if (settings.type === STEP_TYPE.UPLOAD_FILE && settings.value && is(Object, settings.value)) {
      const savedArtifact = yield call(createStepArtifact, { file: settings.value });
      data.projectArtifactId = savedArtifact.id;
      data.value = null;
    }
    const { data: updatedStep } = yield call(api.tests.updateStepSettings, id, groupId, data);

    // FIXME: This is a temporary solution to decrease number of re-renders on TestDetails view invoked but steps changes.
    // Better (but also time consuming) solution is store refactoring (spliting steps object to groupsSteps).
    yield call(deleteGroupsStepsListCache, groupId, stepsList);

    yield put(TestActions.saveStepSettingsSuccess(id, updatedStep, meta));
    yield call(refreshPlaybackCursorPosition);
  } catch (error) {
    yield call(showStepError, ACTIONS.UPDATE, error, error?.response?.status === NOT_FOUND);
    yield put(TestActions.saveStepSettingsFailure(error, meta));
  }
}

export function* createStepsGroupRequest({ testId, name, stepsIds, atIndex }) {
  try {
    const params = { testId, name, stepsIds, atIndex };
    const { data: group } = yield call(api.groups.create, params);

    yield put(TestActions.createStepsGroupSuccess(testId, group, stepsIds, atIndex));
    yield call(refreshPlaybackCursorPosition);
  } catch (error) {
    yield call(showStepsError, ACTIONS.UPDATE, error);
    yield put(TestActions.createStepsGroupFailure(error));
  }
}

export function* splitGroupRequest({ testId, groupId, atIndex }) {
  const pendingToast = yield call(showPendingGroup, ACTIONS.SPLIT);

  try {
    const currentGroup = yield select(selectGroup(false, groupId));
    const currentTest = yield select(selectSingleTest);
    const currentGroupIndex = currentTest.groups.findIndex((id) => id === currentGroup.id);
    const name = null;
    const stepsIds = currentGroup.steps.slice(atIndex);

    const params = { testId, name, stepsIds, atIndex: currentGroupIndex + 1 };
    const { data: group } = yield call(api.groups.split, groupId, params);

    yield put(TestActions.splitGroupSuccess(testId, group, stepsIds, currentGroupIndex + 1));
    yield call(pendingToast.success);
    yield call(refreshPlaybackCursorPosition);
  } catch (error) {
    const shouldShowRequestError = yield call(pendingToast.shouldShowRequestError, error);
    if (shouldShowRequestError) {
      yield call(pendingToast.error);
    } else {
      yield call(pendingToast.dismiss);
    }
    yield put(TestActions.splitGroupFailure(error));
  }
}

export function* updateRequest({ id, test, meta }) {
  try {
    const params = { testId: id, ...test };
    yield call(api.tests.update, id, params);
    yield put(TestActions.updateSuccess(id, test, meta));

    if (!test.runProfileId && !equals(test, { runProfileId: test.runProfileId })) {
      yield call(toasts.showSuccess, {
        content: i18n.t('test.updateSuccessInfo', 'Test has been updated successfully.'),
      });
    }
  } catch (error) {
    yield call(showTestError, ACTIONS.UPDATE, error);
    yield put(TestActions.updateFailure(error, meta));
  }
}

export function* setRecordingState({ data: test }) {
  const isRecording = yield select(selectTestIsRecording);
  if (!isRecording) {
    yield call(analytics.trackEvent, TRACK_EVENT_TYPE.RECORDING_STOPPED);
  }
  yield put(TestActions.setRecordingStateSuccess(test));
  yield put(TestActions.setIsSingleLoading(false));
}

export function* removeGroupRequest({ id, meta }) {
  try {
    yield call(api.groups.remove, id);

    const stepPositionBeforeRemovedSteps = yield call(findStepPositionBeforeRemovedStepsAndGroups, {
      removedStepsIds: [],
      removedGroupsIds: [id],
    });

    yield put(TestActions.removeGroupSuccess(id, meta));

    yield call(setPlaybackCursorPositionAfterStep, stepPositionBeforeRemovedSteps);
    yield call(stopRunnerIfRemovedAllSteps);
  } catch (error) {
    yield call(showGroupError, ACTIONS.DELETE, error);
    yield put(TestActions.removeGroupFailure(error, meta));
  }
}

export function* removeGroupRelationRequest({ testId, groupId, meta }) {
  try {
    const params = { groupId };
    yield call(api.tests.removeGroupRelation, testId, groupId, params);
    yield put(UIStateActions.forgetCollapsedGroup({ collapsedGroupId: groupId, testId }));
    yield put(TestActions.removeGroupRelationSuccess(testId, groupId, meta));
  } catch (error) {
    yield call(showGroupError, ACTIONS.UPDATE, error);
    yield put(TestActions.removeGroupFailure(error, meta));
  }
}

export function* renameGroupRequest({ id, name, meta }) {
  try {
    yield call(api.groups.rename, id, name);
    yield put(TestActions.renameGroupSuccess(id, name, meta));
  } catch (error) {
    yield call(showGroupError, ACTIONS.RENAME, error, true);
    yield put(TestActions.renameGroupFailure(error, meta));
  }
}

export function* searchComponentsRequest({ query = '' }) {
  try {
    const project = yield select(selectSingleProject);
    const params = {
      query,
      project_id: project.id,
    };

    const { data: components } = yield call(api.groups.searchComponents, params);
    const preparedComponents = components.map((group) => ({
      ...group,
      name: group.name || i18n.t('default.group.name_other', { steps: group.steps.length }),
    }));
    yield put(TestActions.searchComponentsSuccess(preparedComponents));
  } catch (error) {
    yield call(toasts.tests.showSearchComponentsError);
    yield put(TestActions.searchComponentsFailure(error));
  }
}

export function* insertGroupRequest({ testId, groupId, atIndex }) {
  const pendingToast = yield call(showPendingGroup, ACTIONS.INSERT);
  try {
    const { data: insertedGroup } = yield call(api.tests.insertGroup, testId, {
      groupId,
      atIndex,
    });
    yield put(TestActions.insertGroupSuccess(testId, insertedGroup, atIndex));
    yield call(pendingToast.success);
    yield call(refreshPlaybackCursorPosition);
  } catch (error) {
    const shouldShowRequestError = yield call(pendingToast.shouldShowRequestError, error);
    if (shouldShowRequestError) {
      yield call(pendingToast.error);
    } else {
      yield call(pendingToast.dismiss);
    }
    yield put(TestActions.insertGroupFailure(error));
  }
}

export function* setGroupAsComponentRequest({ id }) {
  try {
    yield call(analytics.trackEvent, TRACK_EVENT_TYPE.COMPONENT_MADE);
    yield call(api.groups.update, id, { isComponent: true });
    yield put(TestActions.setGroupAsComponentSuccess(id));
  } catch (error) {
    yield call(showGroupError, ACTIONS.UPDATE, error);
    yield put(TestActions.setGroupAsComponentFailure(error));
  }
}

export function* unlinkComponentRequest({ testId, groupId, meta }) {
  try {
    const { data: newGroup } = yield call(api.tests.unlinkComponent, testId, { groupId });

    yield put(UIStateActions.forgetCollapsedGroup({ collapsedGroupId: groupId, testId }));
    yield put(TestActions.unlinkComponentSuccess(groupId, newGroup, meta));
  } catch (error) {
    yield call(showGroupError, ACTIONS.UPDATE, error);
    yield put(TestActions.unlinkComponentFailure(error, meta));
  }
}

function* toggleStepActiveRequest({ id }) {
  try {
    const step = yield select(selectTestStep(id));
    const stepsList = yield select(selectGroupStepsList(step.groupId));
    const params = {
      isActive: !step.isActive,
    };
    yield put(TestActions.toggleStepActiveSuccess(id, params));
    yield call(api.tests.updateStep, id, step.groupId, params);
    yield call(deleteGroupsStepsListCache, step.groupId, stepsList);
    yield call(refreshPlaybackCursorPosition);
  } catch (error) {
    yield call(showStepError, ACTIONS.UPDATE, error);
    yield put(TestActions.toggleStepActiveFailure(error));
  }
}

export function* cloneStepRequest({ id }) {
  try {
    const step = yield select(selectTestStep(id));

    if (step.isTemporary) {
      yield put(TestActions.cloneStepSuccess({ ...step, id: uuid() }, id));
      return;
    }

    const { data: responseData } = yield call(api.tests.cloneStep, id, step.groupId);
    yield put(TestActions.cloneStepSuccess(responseData, id));
    yield put(UIStateActions.addRecentlyAddedStepsIds({ stepsIds: [responseData.id] }));
    yield call(refreshPlaybackCursorPosition);
  } catch (error) {
    yield call(showStepError, ACTIONS.CLONE, error);
    yield put(TestActions.cloneStepFailure(error));
  }
}

export function* cloneGroupRequest({ id, testId }) {
  try {
    const params = { testId };
    const { data: responseData } = yield call(api.groups.clone, id, params);

    yield put(TestActions.cloneGroupSuccess(id, responseData));
    yield put(
      UIStateActions.addRecentlyAddedStepsIds({
        stepsIds: responseData.steps.map((step) => step.id),
      }),
    );
    yield call(refreshPlaybackCursorPosition);
  } catch (error) {
    yield call(showGroupError, ACTIONS.CLONE, error);
    yield put(TestActions.cloneGroupFailure(error));
  }
}

export function* startRunning({ testId, params }) {
  const pendingToast = yield call(showPendingTest, ACTIONS.START);

  try {
    if (params.unconfirmedIndex) {
      const group = yield select(selectTestGroup(params.unconfirmedRelatedGroupId));
      // eslint-disable-next-line no-param-reassign
      params.stopAtStepId = group.steps[params.unconfirmedIndex - 1];
    }

    const data = {
      ...params,
      testId,
    };
    const { data: testRun } = yield call(api.extension.runner.start, data);

    if (testRun) {
      yield call(setCurrentTestRun, { testRun, redirect: params.redirect });
      yield call(pendingToast.dismiss);
      const organizationId = yield select(selectCurrentOrganizationId);
      yield call(toasts.tests.showTestStarted, testRun, organizationId);
    }

    yield put(UIStateActions.resetRecentlyAddedStepsIds());
  } catch (error) {
    const errorMessage = path(['response', 'data', 0, 'message'], error);

    yield call(pendingToast.error, errorMessage);
    yield put(TestActions.startRunningFailure(error));
    yield put(UIStateActions.disablePlaybackSelectedPositions());
  }
}

export function* stopRunning({ testId, params = {} }) {
  try {
    const extensionSettings = yield select(selectExtensionSettings);
    let testRun;
    if (isNewPlaybackSupported(extensionSettings?.version)) {
      const { data } = yield call(api.extension.runner.stop, {
        testId,
        ...params,
      });
      testRun = data;
    } else {
      const channelName = yield select(selectWebsocketChannelName);
      const payload = {
        channelName,
        ...params,
      };
      const { data } = yield call(api.tests.stop, testId, payload);
      testRun = data;
    }

    const testRunId = params.testRunId || testRun.id;
    yield put(TestActions.stopRunningSuccess(testId, testRun));
    yield put(TestRunActions.getSingleSuccess(testRun));
    if (testRunId) {
      yield put(TestRunActions.updateStatusSucceeded(testRunId, RUN_STATUS.STOPPED));
    }
  } catch (error) {
    yield call(showTestsError, ACTIONS.STOP, error);
    yield put(TestActions.stopRunningFailure(error));
  }
}

export function* stop({ ids }) {
  try {
    let testRunsIds;
    if (ids) {
      testRunsIds = yield select(selectLastRunsByTestsIds(ids));
    }
    yield put(TestRunActions.stopRequest(testRunsIds));
    const result = yield take([TestRunTypes.STOP_SUCCESS, TestRunTypes.STOP_FAILURE]);

    if (result.type === TestRunTypes.STOP_FAILURE) {
      throw result.error;
    }

    yield put(TestActions.stopSuccess(ids));
  } catch (error) {
    yield call(showTestsError, ACTIONS.STOP, error);
    yield put(TestActions.stopFailure(error));
  }
}

export function* startRecording({ testId, initialUrl, intoGroupId, sessionId }) {
  try {
    const channelName = yield select(selectWebsocketChannelName);
    const extensionSettings = yield select(selectExtensionSettings);

    if (isNewPlaybackSupported(extensionSettings?.version)) {
      const { data: testRun } = yield call(api.extension.recorder.start, {
        url: initialUrl,
        testId,
        startAfterStepId: null,
        intoGroupId,
        sessionId,
      });
      yield call(setCurrentTestRun, { testRun, redirect: true });
    } else {
      const data = {
        channelName,
        url: initialUrl,
      };
      yield call(api.tests.startRecording, testId, data);
    }

    const hasNotStartedRecording = yield select(selectHasNotStartedRecording);
    if (hasNotStartedRecording) {
      yield call(analytics.trackEvent, TRACK_EVENT_TYPE.FIRST_RECORDING_STARTED);
    }
    yield put(UIStateActions.resetRecentlyAddedStepsIds());
  } catch (error) {
    yield call(showTestError, ACTIONS.START_RECORD, error, true);
    yield put(TestActions.startRecordingFailure(error));
  }
}

export function* stopRecording({ testId, testRunId }) {
  try {
    const extensionSettings = yield select(selectExtensionSettings);
    if (isNewPlaybackSupported(extensionSettings?.version)) {
      const { data: test } = yield call(api.extension.recorder.stop, { testId, testRunId });
      yield put(TestActions.stopRecordingSuccess(test));
    } else {
      const channelName = yield select(selectWebsocketChannelName);
      const data = {
        channelName,
      };
      const { data: test } = yield call(api.tests.stopRecording, testId, data);
      yield put(TestActions.stopRecordingSuccess(test));
    }
  } catch (error) {
    yield call(showTestError, ACTIONS.STOP_RECORD, error, true);
    yield put(TestActions.stopRecordingFailure(error));
  }
}

export function* startRunningSelectedTestsRequested({ testsIds, params = {} }) {
  try {
    const projectId = yield select(selectSingleProjectId);

    const data = {
      projectId,
      testsIds,
      runMode: RUN_ENV.LOCAL,
      ...params,
    };
    const { data: testRuns } = yield call(api.tests.runSelected, data);
    yield put(TestActions.startRunningSelectedTestsSuccess());

    const organizationId = yield select(selectCurrentOrganizationId);
    yield call(toasts.tests.showTestsStarted, testRuns, organizationId);
  } catch (error) {
    yield call(showTestsError, ACTIONS.START, error);
    yield put(TestActions.startRunningSelectedTestsFailure(error));
  }
}

export function* debugRunNextStep({ id }) {
  try {
    const userSettings = yield select(selectUserSettings);
    const testRunId = yield select(selectTestRunIdFromUrl);
    const data = {
      testRunId,
      runMode: userSettings.runMode,
    };
    yield call(api.tests.debugRunNextStep, id, data);
    yield put(TestActions.debugRunNextStepSuccess());
  } catch (error) {
    yield call(showStepError, ACTIONS.START, error);
    yield put(TestActions.debugRunNextStepFailure(error));
  }
}

export function* debugPauseTest({ id, testRunId, runMode }) {
  try {
    const data = { testRunId, runMode };
    yield call(api.tests.debugPauseTest, id, data);
    yield put(TestActions.debugPauseTestSuccess());
  } catch (error) {
    yield call(showStepError, ACTIONS.STOP, error);
    yield put(TestActions.debugPauseTestFailure(error));
  }
}

export function* debugResumeTest({ id, testRunId, runMode }) {
  try {
    const data = { testRunId, runMode };
    yield call(api.tests.debugResumeTest, id, data);
    yield put(TestActions.debugResumeTestSuccess());
    if (testRunId) {
      yield put(TestRunActions.updateStatusSucceeded(testRunId, RUN_STATUS.RUNNING));
    }
  } catch (error) {
    yield call(showStepError, ACTIONS.START, error);
    yield put(TestActions.debugResumeTestFailure(error));
  }
}

export function* createStepRequest({ step, testId, groupId, atIndex, meta }) {
  try {
    const data = { ...step, testId, atIndex };

    if (data.type === STEP_TYPE.UPLOAD_FILE) {
      data.value = step.value.name;
    }

    if (data.type === STEP_TYPE.UPLOAD_FILE) {
      const savedArtifact = yield call(createStepArtifact, {
        file: step.value,
      });
      data.projectArtifactId = savedArtifact.id;
      data.value = null;
    }

    const { data: savedStep } = yield call(api.groups.createStep, groupId, data);

    yield put(TestActions.createStepSuccess(groupId, savedStep, atIndex, meta));
  } catch (error) {
    yield call(showStepError, ACTIONS.CREATE, error);
    yield put(TestActions.createStepFailure(error, meta));
  }
}

export function* createDefaultStepsGroupWithStepRequest({ testId }) {
  try {
    yield put(TestActions.createStepsGroupRequest(testId, null, [], 0));

    const stepsGroupAction = yield take([
      TestTypes.CREATE_STEPS_GROUP_SUCCESS,
      TestTypes.CREATE_STEPS_GROUP_FAILURE,
    ]);

    if (stepsGroupAction.type === TestTypes.CREATE_STEPS_GROUP_FAILURE) {
      yield put(TestActions.createDefaultStepsGroupWithStepFailure());
      return;
    }

    const homepageUrl = yield select(selectProjectHomepageUrl);
    yield put(
      TestActions.createStepRequest(
        testId,
        stepsGroupAction.group.id,
        { type: STEP_TYPE.GOTO, url: homepageUrl },
        0,
      ),
    );

    const stepAction = yield take([TestTypes.CREATE_STEP_SUCCESS, TestTypes.CREATE_STEP_FAILURE]);
    if (stepAction.type === TestTypes.CREATE_STEP_FAILURE) {
      yield put(TestActions.createDefaultStepsGroupWithStepFailure());
      return;
    }

    yield put(TestActions.createDefaultStepsGroupWithStepSuccess());
  } catch (error) {
    yield put(TestActions.createDefaultStepsGroupWithStepFailure(error));
  }
}

export function* getDefaultStepsParamsRequest() {
  try {
    const { data } = yield call(api.steps.getDefaultParams);

    yield put(TestActions.getDefaultStepsParamsSuccess(data));
  } catch (error) {
    yield call(showGetDefaultStepsParamsError);
    yield put(TestActions.getDefaultStepsParamsFailure(error));
  }
}

function* getDefaultStepParams(stepType, matchingParams) {
  let defaultStepsParams = yield select(selectDefaultStepsParams);

  if (isEmpty(defaultStepsParams)) {
    yield take(TestTypes.GET_DEFAULT_STEPS_PARAMS_SUCCESS);
    defaultStepsParams = yield select(selectDefaultStepsParams);
  }

  return pickDefaultStepParams(stepType, defaultStepsParams, matchingParams);
}

export function* createTemporaryStepRequest({ testId, groupId, stepType, atIndex, params }) {
  try {
    let currentGroupId = groupId;

    if (!currentGroupId) {
      yield put(TestActions.createStepsGroupRequest(testId, null, [], 0));

      const stepsGroupAction = yield take([
        TestTypes.CREATE_STEPS_GROUP_SUCCESS,
        TestTypes.CREATE_STEPS_GROUP_FAILURE,
      ]);

      if (stepsGroupAction.type === TestTypes.CREATE_STEPS_GROUP_FAILURE) {
        yield put(TestActions.createTemporaryStepFailure());
        return;
      }

      currentGroupId = stepsGroupAction.group.id;
    }

    const defaultStepParams = yield call(getDefaultStepParams, stepType, params);
    const initialValues = yield call(getInitialValues, { type: stepType, ...params });

    const step = {
      ...initialValues,
      ...defaultStepParams,
      type: stepType,
      groupId: currentGroupId,
      isExpanded: true,
      isTemporary: true,
      atIndex,
      id: uuid(),
    };

    yield put(TestActions.createTemporaryStepSuccess(testId, currentGroupId, step));
    yield put(UIStateActions.addRecentlyAddedStepsIds({ stepsIds: [step.id] }));
  } catch (error) {
    yield put(TestActions.createTemporaryStepFailure(error));
  }
}

export function* createTemporaryStepFailure({ error }) {
  yield call(showStepError, ACTIONS.CREATE, error);
}

export function* saveTemporaryStepRequest({ temporaryStepId, groupId, step, atIndex, meta }) {
  try {
    const test = yield select(selectSingleTest);

    yield put(TestActions.createStepRequest(test.id, groupId, step, atIndex, meta));

    const stepAction = yield take([TestTypes.CREATE_STEP_SUCCESS, TestTypes.CREATE_STEP_FAILURE]);
    if (stepAction.type === TestTypes.CREATE_STEP_FAILURE) {
      yield put(TestActions.saveTemporaryStepFailure(stepAction.error, meta));
      return;
    }

    yield put(
      TestActions.saveTemporaryStepSuccess(temporaryStepId, groupId, stepAction.step.id, meta),
    );
    yield put(UIStateActions.addRecentlyAddedStepsIds({ stepsIds: [stepAction.step.id] }));

    yield call(refreshPlaybackCursorPosition);
  } catch (error) {
    yield call(showTestsError, ACTIONS.UPDATE, error);
    yield put(TestActions.saveTemporaryStepFailure(error, meta));
  }
}

export function* updateStepsActivation({ testId, steps, value }) {
  try {
    yield call(api.steps.updateActivation, testId, steps, value);
    yield put(TestActions.updateStepsActivationSuccess(steps, value));
    yield call(refreshPlaybackCursorPosition);
  } catch (error) {
    yield put(TestActions.updateStepsActivationFailure(error));
  }
}

export function* updateSteps({ data }) {
  const currentTest = yield select(selectSingleTest);

  if (currentTest?.id === data.testId) {
    const added = data?.delta?.added ?? [];

    for (let index = 0; index < added.length; index += 1) {
      const step = added[index];
      yield call(analytics.trackEvent, TRACK_EVENT_TYPE.RECORDING_STEP_CREATED, {
        [TRACK_EVENT_ARG_TYPE.STEP_TYPE]: step.type,
      });
    }

    yield put(TestActions.updateStepsSuccess(data));
  }
}

export function* passStepScreenshotToTestRun({ data }) {
  yield put(TestRunActions.stepElementScreenshotUpdated(data));
}

export function* updated({ data }) {
  const currentUserId = yield select(selectUserId);
  const results = is(Array, data) ? data : [data];
  const modifiedResult = results.map((result) => {
    const extraData = {
      isTriggeredByCurrentUser: isTriggeredByCurrentUser(result, currentUserId),
    };
    return { ...result, ...extraData };
  });

  yield put(TestActions.updatedSuccess(modifiedResult));
}

export function* setCurrentTestRun({ testRun, redirect }) {
  const organizationId = yield select(selectCurrentOrganizationId);
  const project = yield select(selectSingleProject);
  const test = yield select(selectSingleTest);
  const currentFlags = yield select(selectUserFlags);

  yield put(TestActions.startRunningSuccess(testRun.test.id, testRun));

  if (![RUN_STATUS.PASSED, RUN_STATUS.FAILED, RUN_STATUS.ERROR].includes(testRun.status)) {
    yield put(
      TestRunActions.getSingleSuccess({
        ...testRun,
        isFirstTestRun: !currentFlags?.testStartedLocal && !currentFlags?.testStartedServer,
        testArchive: getRelatedTest(testRun),
      }),
    );
  }

  // Do not redirect if a user already left the test page
  if (redirect && organizationId && test?.id && project?.id) {
    const url = reverse(
      urls.test,
      {
        projectId: project.id,
        projectSlug: project.slug,
        testId: test.id,
        organizationId,
      },
      { testRunId: testRun.id },
    );
    yield put(push(url));
  }

  yield put(TestRunActions.updateStatusSucceeded(testRun.id, testRun.status));
}

export function* findStepPositionBeforeRemovedStepsAndGroups({
  removedStepsIds,
  removedGroupsIds,
}) {
  const sortedSteps = yield select(selectSortedSteps);
  const cursorPosition = yield select(selectPlaybackCursorPosition);
  if (!cursorPosition?.afterStep) return null;

  if (
    !cursorPosition?.afterStep?.id &&
    !removedGroupsIds.includes(cursorPosition.afterStep.groupId)
  ) {
    return {
      id: null,
      groupId: cursorPosition.afterStep.groupId,
    };
  }

  const test = yield select(selectSingleTest);
  const groups = yield select(selectTestGroups);
  const groupIndex = test.groups.indexOf(cursorPosition.afterStep.groupId);

  const afterStepId =
    cursorPosition.afterStep?.id ??
    sortedSteps.findLast((s) => test.groups.indexOf(s.groupId) < groupIndex)?.id;
  const cursorStepIndex = sortedSteps.findIndex((step) => step.id === afterStepId);

  let position = null;
  let searchIndex = cursorStepIndex;
  while (searchIndex >= 0) {
    const step = sortedSteps[searchIndex];
    if (!removedStepsIds.includes(step.id) && !removedGroupsIds.includes(step.groupId)) {
      position = {
        id: step.id,
        groupId: step.groupId,
      };
      break;
    }
    if (
      groups[step.groupId]?.steps.indexOf(step.id) === 0 &&
      !removedGroupsIds.includes(step.groupId)
    ) {
      position = {
        id: null,
        groupId: step.groupId,
      };
      break;
    }
    searchIndex -= 1;
  }

  return position;
}

export function* refreshPlaybackCursorPosition() {
  const cursorPosition = yield select(selectPlaybackCursorPosition);
  if (cursorPosition?.afterStep) {
    yield call(setPlaybackCursorPositionAfterStep, cursorPosition.afterStep);
  }
}

export function* setPlaybackCursorPositionAfterStep(step) {
  const sortedSteps = yield select(selectSortedSteps);

  if (!step) {
    const position = {
      afterStep: {
        id: null,
        groupId: sortedSteps[0]?.groupId,
      },
      runningStep: null,
      nextStep: {
        id: sortedSteps[0]?.id,
        groupId: sortedSteps[0]?.groupId,
      },
    };

    yield put(CommonActions.setPlaybackCursorPosition(position));
    yield call(
      dispatchInExtension,
      CommonActions.setPlaybackCursorPositionIfPausedOrIdle(position),
    );
    return;
  }

  const test = yield select(selectSingleTest);
  const stepIndex = sortedSteps.findIndex((s) => s.id === step.id);
  const updatedStepPosition = sortedSteps[stepIndex];

  const groupIndex = test.groups.indexOf(step.groupId);
  const lastStepIdBeforeCursor =
    step?.id ?? sortedSteps.findLast((s) => test.groups.indexOf(s.groupId) < groupIndex)?.id;
  const lastStepIndexBeforeCursor = sortedSteps.findIndex((s) => s.id === lastStepIdBeforeCursor);
  const nextActiveStep =
    sortedSteps.slice(lastStepIndexBeforeCursor + 1).find((s) => s.isActive) ?? null;

  const position = {
    afterStep: step?.id
      ? {
          id: updatedStepPosition.id,
          groupId: updatedStepPosition.groupId,
        }
      : {
          id: null,
          groupId: step.groupId,
        },
    runningStep: null,
    nextStep: nextActiveStep
      ? {
          id: nextActiveStep.id,
          groupId: nextActiveStep.groupId,
        }
      : null,
  };

  yield put(CommonActions.setPlaybackCursorPosition(position));
  yield call(dispatchInExtension, CommonActions.setPlaybackCursorPositionIfPausedOrIdle(position));
}

export function* stopRunnerIfRemovedAllSteps() {
  const sortedSteps = yield select(selectSortedSteps);
  const testRunId = yield select(selectTestRunIdFromUrl);

  if (sortedSteps.length === 0 && testRunId) {
    const isRecording = yield select(selectTestIsRecording);
    const test = yield select(selectSingleTest);

    if (isRecording) {
      yield put(TestActions.stopRecordingRequest(test.id, testRunId));
    } else {
      yield put(TestActions.stopRunningRequest(test.id, { testRunId }));
    }
  }
}

export default function* testSagas() {
  yield all([
    yield takeLatest(TestTypes.CREATE_REQUEST, createRequest),
    yield takeLatest(TestTypes.GET_LIST_REQUEST, getListRequest),
    yield takeLatest(TestTypes.REMOVE_REQUEST, removeRequest),
    yield takeLatest(TestTypes.REMOVE_PARTIALS_REQUEST, removePartialsRequest),
    yield takeLatest(TestTypes.CLONE_REQUEST, clone),
    yield takeLatest(TestTypes.CREATE_STEP_REQUEST, createStepRequest),
    yield takeLatest(TestTypes.REMOVE_STEPS_REQUEST, removeStepsRequest),
    yield takeLatest(TestTypes.UPDATE_GROUP_POSITION, updateGroupPosition),
    yield takeLatest(TestTypes.UPDATE_STEPS_POSITION, updateStepsPosition),
    yield takeLatest(TestTypes.CREATE_STEPS_GROUP_REQUEST, createStepsGroupRequest),
    yield takeLatest(TestTypes.SPLIT_GROUP_REQUEST, splitGroupRequest),
    yield takeLatest(TestTypes.SAVE_STEP_SETTINGS_REQUEST, saveStepSettings),
    yield takeLatest(TestTypes.UPDATE_REQUEST, updateRequest),
    yield takeLatest(TestTypes.SET_RECORDING_STATE, setRecordingState),
    yield takeLatest(TestTypes.REMOVE_GROUP_REQUEST, removeGroupRequest),
    yield takeLatest(TestTypes.CLONE_GROUP_REQUEST, cloneGroupRequest),
    yield takeLatest(TestTypes.REMOVE_GROUP_RELATION_REQUEST, removeGroupRelationRequest),
    yield takeLatest(TestTypes.RENAME_GROUP_REQUEST, renameGroupRequest),
    yield takeLatest(TestTypes.SEARCH_COMPONENTS_REQUEST, searchComponentsRequest),
    yield takeLatest(TestTypes.INSERT_GROUP_REQUEST, insertGroupRequest),
    yield takeLatest(TestTypes.CLEAN_STEPS_REQUEST, cleanSteps),
    yield takeLatest(TestTypes.TOGGLE_STEP_ACTIVE_REQUEST, toggleStepActiveRequest),
    yield takeLatest(TestTypes.CLONE_STEP_REQUEST, cloneStepRequest),
    yield takeLatest(TestTypes.STOP_REQUEST, stop),
    yield takeLatest(TestTypes.START_RECORDING_REQUEST, startRecording),
    yield takeLatest(TestTypes.STOP_RECORDING_REQUEST, stopRecording),
    yield takeLatest(TestTypes.START_RUNNING_REQUEST, startRunning),
    yield takeLatest(TestTypes.STOP_RUNNING_REQUEST, stopRunning),
    yield takeLatest(
      TestTypes.START_RUNNING_SELECTED_TESTS_REQUEST,
      startRunningSelectedTestsRequested,
    ),
    yield takeLatest(TestTypes.DEBUG_RUN_NEXT_STEP_REQUEST, debugRunNextStep),
    yield takeLatest(TestTypes.DEBUG_PAUSE_TEST_REQUEST, debugPauseTest),
    yield takeLatest(TestTypes.DEBUG_RESUME_TEST_REQUEST, debugResumeTest),
    yield takeLatest(TestTypes.SET_GROUP_AS_COMPONENT_REQUEST, setGroupAsComponentRequest),
    yield takeLatest(TestTypes.UNLINK_COMPONENT_REQUEST, unlinkComponentRequest),
    yield takeLatest(
      TestTypes.CREATE_DEFAULT_STEPS_GROUP_WITH_STEP_REQUEST,
      createDefaultStepsGroupWithStepRequest,
    ),
    yield takeLatest(TestTypes.CREATE_TEMPORARY_STEP_REQUEST, createTemporaryStepRequest),
    yield takeLatest(TestTypes.UPDATE_STEPS_ACTIVATION_REQUEST, updateStepsActivation),
    yield takeLatest(TestTypes.CREATE_TEMPORARY_STEP_FAILURE, createTemporaryStepFailure),
    yield takeLatest(TestTypes.SAVE_TEMPORARY_STEP_REQUEST, saveTemporaryStepRequest),
    yield takeLatest(TestTypes.GET_DEFAULT_STEPS_PARAMS_REQUEST, getDefaultStepsParamsRequest),
    yield takeLatest(TestTypes.STEP_ELEMENT_SCREENSHOT_UPDATED, passStepScreenshotToTestRun),
    yield takeLatest(TestTypes.UPDATE_STEPS, updateSteps),
    yield takeLatest(TestTypes.REFRESH_PLAYBACK_CURSOR_POSITION, refreshPlaybackCursorPosition),
    //
    yield takeLatest(TestRunTypes.UPDATED, updated),
    yield takeLatest(TestRunTypes.UPDATED_MULTIPLE, updated),
  ]);
}
