import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef } from 'react';

import type { CheckboxStyledProps, CheckmarkContainerStyledProps } from './Checkbox.types';
import type { CustomEventHandler } from '../../types/forms';
import type { MouseEventHandler, ReactNode } from 'react';

import * as S from './Checkbox.styled';

interface CheckboxProps extends CheckboxStyledProps {
  className?: string;
  'data-testid'?: string;
  'aria-label'?: string;
  name?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  children?: ReactNode;
  onChange?: (params: CustomEventHandler) => void;
  onClick?: MouseEventHandler<HTMLInputElement>;
  small?: boolean;
  checked?: boolean;
}

const Checkbox = memo(
  forwardRef<HTMLInputElement, CheckboxProps & CheckmarkContainerStyledProps>(
    (
      {
        className,
        checked,
        children,
        onClick,
        onChange,
        indeterminate,
        name,
        'data-testid': dataTestId = 'Checkbox',
        'aria-label': ariaLabel,
        disabled,
        small,
      },
      ref,
    ) => {
      const checkboxRef = useRef<HTMLInputElement>(null);
      const handleClick = useCallback<MouseEventHandler<HTMLInputElement>>(
        (event) => {
          onClick?.(event);
          event.stopPropagation();
        },
        [onClick],
      );

      useEffect(() => {
        if (checkboxRef.current) {
          checkboxRef.current.indeterminate = !!(!checked && indeterminate);
        }
      }, [checked, indeterminate]);

      useImperativeHandle(ref, () => checkboxRef.current as HTMLInputElement, []);

      return (
        <S.Container
          disabled={disabled}
          onClick={handleClick}
          className={className}
          data-testid={dataTestId}
        >
          <S.Input
            data-testid={`${dataTestId}.Input`}
            name={name}
            onChange={onChange}
            ref={checkboxRef}
            checked={checked}
            disabled={disabled}
            aria-label={ariaLabel ?? String(children)}
          />
          <S.CheckmarkContainer small={small}>
            <S.CheckIcon />
            <S.IndeterminateIcon />
          </S.CheckmarkContainer>
          {!!children && <S.Label>{children}</S.Label>}
        </S.Container>
      );
    },
  ),
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
